import { useContext, useEffect, useState } from 'react'
import FadeIn from 'global/fadein'
import DiffNext from './DiffNext'

// MUI imports
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Divider from '@mui/material/Divider'
import Icon from '@mui/material/Icon'
import Stack from '@mui/material/Stack'
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight'
import Tooltip from '@mui/material/Tooltip'
import { Theme } from '@mui/material/styles/createTheme'

// CompendiumX components
import {
  CXIconArticle,
  CXIconArticleAlt,
  CXIconRevisions,
  CXIconTemplate,
} from 'components/CXIcon/CXIcon'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'

// Context
import { ErrorContext } from 'context/ErrorContext'
import { UserContext } from 'context/UserContext'

// Functional
import { getMetadata, getRevisions, shareDevops, shareJira } from './MetadataPanel.service'
import { truncateString } from './MetadataPanel.helpers'

interface Props {
  documentId: string
  articleId: string
  metadataPanelActive: boolean
  handleSetMetadataPanelActive: (bool: boolean) => void
  revisionsTabEnforced: boolean
}

const MetadataPanel: React.FC<Props> = (props) => {
  const { documentId, articleId, metadataPanelActive, handleSetMetadataPanelActive, revisionsTabEnforced } = props

  // User information
  const { userRoles } = useContext(UserContext)
  const accessShareJira = userRoles && userRoles?.indexOf('share_jira') > -1
  const accessShareDevops = userRoles && userRoles?.indexOf('share_devops') > -1
  const accessShareCollibra = userRoles && userRoles.indexOf('share_devops') > -1
  const accessDocCompare = userRoles && userRoles?.indexOf('document_compare') > -1

  // Data
  const [loading, setLoading] = useState(false)
  const [metadata, setMetadata] = useState<any>()
  const [revisions, setRevisions] = useState<any>()
  const [revisionsLoading, setRevisionsLoading] = useState(false)
  const [devopsShareLoading, setDevopShareLoading] = useState(false)
  const [devopsShared, setDevopsShared] = useState(false)

  const [jiraShareLoading, setJiraShareLoading] = useState(false)
  const [jiraShared, setJiraShared] = useState(false)
  // Error handling
  const { setErrorMessage } = useContext(ErrorContext)

  const [metaDataTabs, setMetadataTabs] = useState<any[]>([])
  useEffect(() => {
    const tabs = [
      {
        name: 'Article details',
        icon: <CXIconArticleAlt />,
        active: !revisionsTabEnforced, // false if enforced
        id: '1',
        visible: true,
      },
      {
        name: 'Related articles',
        icon: <CXIconArticle />,
        active: false,
        id: '3',
        visible: true,
      },
      {
        name: 'Related templates',
        icon: <CXIconTemplate />,
        active: false,
        id: '4',
        visible: true,
      },
      {
        name: 'Revisions',
        icon: <CXIconRevisions />,
        active: revisionsTabEnforced, // true if enforced
        id: '5',
        visible: accessDocCompare,
      },
    ]
    setMetadataTabs(tabs)
  }, [accessDocCompare, revisionsTabEnforced])

  useEffect(() => {
    const fetchMetadata = async (): Promise<void> => {
      setLoading(true)
      try {
        setMetadata(await getMetadata(articleId))
      } catch (error: any) {
        setErrorMessage(error)
      }
      setLoading(false)
    }
    if (articleId) {
      fetchMetadata()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articleId])

  // Get Revisions
  useEffect(() => {
    const fetchRevisions = async (): Promise<void> => {
      setRevisionsLoading(true)
      try {
        const activeTabId = metaDataTabs.find((tab) => tab.active)?.id
        if (activeTabId === '5' || revisionsTabEnforced) {
          setRevisions(await getRevisions(articleId))
        }
      } catch (error: any) {
        setErrorMessage(error)
      }
      setRevisionsLoading(false)
    }
    if (!loading) {
      fetchRevisions()
    }
    // eslint-disable-next-line
  }, [articleId, metaDataTabs, revisionsTabEnforced])

  // Scroll to metadata
  const setTabActive = (tabId: string) => {
    setMetadataTabs(
      metaDataTabs.map((tab) =>
        tab.id === tabId ? { ...tab, active: true } : { ...tab, active: false }
      )
    )
    const foundId = articleId ? window.document?.getElementById(tabId) : ''
    if (foundId) {
      foundId.scrollIntoView({ behavior: 'smooth' })
    }
  }

  // Share with Devops
  const shareWithDevops = async () => {
    setDevopShareLoading(true)
    try {
      setDevopsShared(await shareDevops(articleId || ''))
    } catch (error: any) {
      setErrorMessage(error)
    }
    setDevopShareLoading(false)
  }

  const shareDevopsButton = () => {
    if (!accessShareDevops) return null
    return (
      <Box my={2} sx={{ display: 'flex', justifyContent: 'flex-end', height: '35px' }}>
        <Box sx={{ mr: 1, mt: '10px' }}>
          {devopsShareLoading && (
            <FadeIn visible={devopsShareLoading}>
              <CircularProgress size={20} />
            </FadeIn>
          )}
          <FadeIn visible={devopsShared}>
            <Tooltip title="Shared to devops">
              <Icon fontSize="medium" color="success">
                check_circle
              </Icon>
            </Tooltip>
          </FadeIn>
        </Box>
        <MDButton
          color="dark"
          variant="outlined"
          sx={{ fontSize: '12px !important', padding: '0 14px' }}
          disabled={devopsShareLoading || devopsShared}
          onClick={shareWithDevops}
        >
          Share with Devops
        </MDButton>
      </Box>
    )
  }

  // Share with Jira
  const shareWithJira = async () => {
    setJiraShareLoading(true)
    try {
      setJiraShared(await shareJira(articleId || ''))
    } catch (error: any) {
      setErrorMessage(error)
    }
    setJiraShareLoading(false)
  }

  const shareJiraButton = () => {
    if (!accessShareJira) return null
    return (
      <Box my={2} sx={{ display: 'flex', justifyContent: 'flex-end', height: '35px' }}>
        <Box sx={{ mr: 1, mt: '10px' }}>
          {jiraShareLoading && (
            <FadeIn visible={jiraShareLoading}>
              <CircularProgress size={20} />
            </FadeIn>
          )}
          <FadeIn visible={jiraShared}>
            <Tooltip title="Shared to Jira">
              <Icon fontSize="medium" color="success">
                check_circle
              </Icon>
            </Tooltip>
          </FadeIn>
        </Box>
        <MDButton
          color="dark"
          variant="outlined"
          sx={{ fontSize: '12px !important', padding: '0 14px' }}
          disabled={jiraShareLoading || jiraShared}
          onClick={shareWithJira}
        >
          Share with Jira
        </MDButton>
      </Box>
    )
  }

  // "Go to Collibra" button
  // We only show it if the user has the role 'devops' (based on accessShareCollibra).
  const shareCollibraButton = () => {
    if (!accessShareCollibra) return null

    // Example Collibra brand color. You can adjust to your brand palette.
    // If you want an 'outlined' look, just remove the backgroundColor and update borderColor accordingly.
    const collibraGreen = '#91C736'

    return (
      <Box my={2} sx={{ display: 'flex', justifyContent: 'flex-end', height: '35px' }}>
        <MDButton
          // We'll use a custom styling here to mimic Collibra’s color scheme
          sx={{
            fontSize: '12px !important',
            padding: '0 14px',
            backgroundColor: collibraGreen,
            color: '#FFFFFF',
            borderColor: collibraGreen,
            '&:hover': {
              backgroundColor: '#7AA420',
              borderColor: '#7AA420',
            },
          }}
          onClick={() => {
            window.open(
              `https://regbird.collibra.com/search?page=1&q="${metadata?.toc_path_full_text} > ${metadata?.article_nr}"`,
              'Collibra'
            )
          }}
        >
          Go to Collibra
        </MDButton>
      </Box>
    )
  }

  const metaDataOpener = () => {
    return (
      <Box sx={{ mt: 2, position: 'absolute', width: '100%' }}>
        <Box
          sx={{
            cursor: 'pointer',
            borderRadius: '4px 0px 0px 4px',
            border: '1px solid #D0D5DD',
            borderRightColor: 'white.main',
            backgroundColor: 'white.main',
            color: 'dark.main',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '140px',
            height: '40px',
            ml: 'auto !important',
            p: '0',
            '& svg': {
              height: '15px',
            },
          }}
          onClick={() => handleSetMetadataPanelActive(true)}
        >
          <Icon>menu_open</Icon>
          <MDTypography variant="textSM_M" ml={2} color="primary.focus">
            Open metadata
          </MDTypography>
        </Box>
      </Box>
    )
  }

  if (!metadataPanelActive) {
    return metaDataOpener()
  }

  return (
    <Box sx={{ position: 'absolute', width: '100%', height: '90%', left: '25px' }}>
      {!loading && (
        <Stack
          direction="row"
          spacing={2}
          sx={({ palette: { grey } }: Theme) => ({ background: grey[400], pl: 2 })}
        >
          {metaDataTabs
            .filter((tab) => tab.visible)
            .map((tab) => (
              <Box
                key={tab.id}
                sx={{
                  cursor: 'pointer',
                  borderRadius: '4px 4px 0px 0px',
                  border: '1px solid #D0D5DD',
                  backgroundColor: !tab.active ? 'white.main' : '#E7E7FF',
                  color: !tab.active ? 'dark.main' : '#4D4DCC',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: !tab.active ? '40px' : 'auto',
                  height: !tab.active ? '40px' : 'auto',
                  maxHeight: '40px',
                  p: tab.active ? '10px 10px 7px 10px' : '0',
                  '& svg': {
                    height: '15px',
                  },
                }}
                onClick={() => setTabActive(tab.id)}
              >
                {tab.icon}
                {tab.active && (
                  <MDTypography variant="textSM_M" ml={1} color="primary.focus">
                    {tab.name}
                  </MDTypography>
                )}
              </Box>
            ))}
          {/* Close button */}
          <Box
            sx={{
              cursor: 'pointer',
              borderRadius: '4px 4px 0px 0px',
              border: '1px solid #D0D5DD',
              backgroundColor: 'white.main',
              color: 'dark.main',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '40px',
              height: '40px',
              ml: 'auto !important',
              mr: '24px !important',
              p: '0',
              '& svg': {
                height: '15px',
              },
            }}
            onClick={() => handleSetMetadataPanelActive(false)}
          >
            <Icon>close</Icon>
          </Box>
        </Stack>
      )}
      <Box
        sx={({ borders }: Theme) => ({
          backgroundColor: '#FFFFFF',
          borderTopLeftRadius: borders.borderRadius,
          borderBottomLeftRadius: borders.borderRadius,
          height: '100%',
        })}
      >
        <Box
          sx={{
            height: '90%',
            overflow: 'scroll',
            position: 'relative',
            justifyContent: 'center',
          }}
          py={5}
          mx={6}
          pr={6}
        >
          {/* Revisions Tab Content */}
          {metaDataTabs.find((tab) => tab.active)?.id === '5' && (
            <Box
              sx={{
                backgroundColor: '#FFFFFF',
                position: 'absolute',
                height: '100%',
                width: '100%',
              }}
            >
              {revisionsLoading && (
                <Stack spacing={4} sx={{ alignItems: 'center' }}>
                  <CircularProgress size={100} />
                  <MDTypography
                    variant="h6"
                    sx={({ palette: { grey } }: Theme) => ({ color: grey[500] })}
                  >
                    Loading revisions...
                  </MDTypography>
                </Stack>
              )}
              {!revisionsLoading &&
                revisions?.article_content &&
                revisions?.previous_article_content && (
                  <DiffNext
                    inputA={revisions.previous_article_content}
                    inputB={revisions.article_content}
                    type="wordsWithSpace"
                  />
                )}
              {!revisionsLoading &&
                revisions?.article_content &&
                revisions?.previous_article_content === '' && (
                  <MDTypography sx={{ color: '#039855' }} variant="textMD">
                    {revisions.article_content}
                  </MDTypography>
                )}
              {!revisionsLoading &&
                revisions?.article_content === '' &&
                revisions?.previous_article_content === '' && (
                  <MDTypography variant="textLG" mb={1} sx={{ color: 'grey.500', display: 'block' }}>
                    No revisions available for this selection.
                  </MDTypography>
                )}
            </Box>
          )}
          {/* Main Metadata */}
          {loading && (
            <Box sx={{ height: '100vh', width: '100%' }}>
              <Stack spacing={3} sx={{ alignItems: 'center', mt: '150px' }}>
                <CircularProgress size={50} />
                <MDTypography>Loading document metadata ...</MDTypography>
              </Stack>
            </Box>
          )}
          {!loading && !metadata?.article_anchor && (
            <Box sx={{ height: '0', width: '100%' }}>
              <Stack spacing={3} sx={{ alignItems: 'center', mt: '150px' }}>
                <MDTypography>No metadata available</MDTypography>
              </Stack>
            </Box>
          )}
          {/* Show content if we have metadata & not on the Revisions tab */}
          {!loading &&
            metadata?.article_anchor &&
            metaDataTabs.find((tab) => tab.active)?.id !== '5' && (
              <>
                {/* Article details */}
                <Box mb={7} id="1">
                  <Box
                    py={1}
                    px={1}
                    sx={({ borders: { borderRadius } }: Theme) => ({
                      cursor: 'default',
                      display: 'inline-flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: '#98A2B3',
                      color: 'white.main',
                      borderRadius: borderRadius.md,
                    })}
                  >
                    <CXIconArticle sx={{ mr: 1 }} />
                    <MDTypography variant="textSM_M" color="white">
                      {metadata?.document_longname}
                    </MDTypography>
                  </Box>
                  {metadata?.toc_path_long_object?.map((toc: any, index: number) => {
                    const isLast = index === metadata?.toc_path_long_object.length - 1
                    return (
                      <Box
                        key={`${toc.toc_anchor}-${index}`}
                        sx={{
                          display: 'block',
                          alignItems: 'center',
                          my: 1,
                        }}
                      >
                        <SubdirectoryArrowRightIcon sx={{ mr: 1 }} />
                        <Box
                          py={1}
                          px={2}
                          onClick={() => {
                            window.location.href = `/article/${documentId}/${toc.toc_anchor}`
                          }}
                          sx={({ borders: { borderRadius } }: Theme) => ({
                            cursor: 'pointer',
                            display: 'inline-flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: isLast ? '#A0A0FF' : '#EAECF0',
                            borderRadius: borderRadius.md,
                            maxWidth: '320px',
                          })}
                        >
                          {isLast && <CXIconArticle sx={{ mr: 1, color: 'white.main' }} />}
                          <MDTypography variant="caption1" color={isLast ? 'white' : 'dark'}>
                            {toc?.toc_nr}: {toc?.toc_title && truncateString(toc.toc_title)}
                          </MDTypography>
                        </Box>
                      </Box>
                    )
                  })}
                </Box>
                {/* Title + Description */}
                <Box my={7}>
                  <MDTypography variant="textXL_SB" mb={1}>
                    {`${metadata?.article_nr}: ${metadata?.article_title}`}
                  </MDTypography>
                  <MDTypography
                    variant="textSM"
                    dangerouslySetInnerHTML={{ __html: metadata?.article_content }}
                    sx={{ display: 'block' }}
                  />
                  {/* Share Buttons */}
                  <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    {/* “Go to Collibra” button (only shown if user has devops role) */}
                    {shareCollibraButton()}
                    {shareJiraButton()}
                    {shareDevopsButton()}
                  </Box>
                </Box>
                <Divider />
                {/* Related articles */}
                <Box my={7} id="3">
                  <MDTypography variant="textXL_SB" mb={1}>
                    Related articles
                  </MDTypography>
                  {!metadata?.related_articles?.length && (
                    <MDTypography
                      variant="textLG"
                      mb={1}
                      sx={{ color: 'grey.500', display: 'block' }}
                    >
                      No related articles available
                    </MDTypography>
                  )}
                  {metadata?.related_articles?.map((relatedArticle: any) => (
                    <Box
                      key={`${relatedArticle.article_anchor}`}
                      p={2}
                      my={3}
                      width="100%"
                      onClick={() => {
                        window.location.href = `/article/${relatedArticle?.document_id}/${relatedArticle?.article_anchor}`
                      }}
                      sx={({ borders: { borderColor, borderWidth, borderRadius } }: Theme) => ({
                        cursor: 'pointer',
                        border: `${borderWidth[1]} solid ${borderColor}`,
                        borderRadius: borderRadius.lg,
                      })}
                    >
                      <Stack>
                        <MDTypography variant="textSM_M">{`${relatedArticle?.document_shortname}: ${relatedArticle?.article_nr}`}</MDTypography>
                        <MDTypography variant="textSM">
                          {relatedArticle?.article_title}
                        </MDTypography>
                      </Stack>
                    </Box>
                  ))}
                </Box>
                <Divider />
                {/* Related templates */}
                <Box my={7} id="4">
                  <MDTypography variant="textXL_SB" sx={{ fontWeight: 600 }} mb={1}>
                    Related templates
                  </MDTypography>
                  {!metadata?.related_templates?.length && (
                    <MDTypography
                      variant="textLG"
                      mb={1}
                      sx={{ color: 'grey.500', display: 'block' }}
                    >
                      No related templates available
                    </MDTypography>
                  )}
                  {metadata?.related_templates?.map((relatedTemplate: any) => (
                    <Box
                      key={`${relatedTemplate.TemplateCode}`}
                      p={2}
                      my={3}
                      width="90%"
                      sx={({ borders: { borderColor, borderWidth, borderRadius } }: Theme) => ({
                        border: `${borderWidth[1]} solid ${borderColor}`,
                        borderRadius: borderRadius.lg,
                      })}
                    >
                      <Stack>
                        <MDTypography variant="textSM_M">
                          {relatedTemplate?.TaxonomyCode + ' ' + relatedTemplate?.TemplateCode}
                        </MDTypography>
                        <MDTypography variant="textSM">
                          {relatedTemplate?.TemplateLabel}
                        </MDTypography>
                      </Stack>
                    </Box>
                  ))}
                </Box>
              </>
            )}
        </Box>
      </Box>
    </Box>
  )
}

export default MetadataPanel
